/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  Label,
  Table,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//CSV File
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
// Redux
import { connect } from 'react-redux';
import { inputCsv } from '../../store/business-point/actions';
import { list, create, listAll, setAll } from '../../store/rfid/actions';
import { buildTitle } from 'Apps';

const fileReader = new FileReader();
class RFIDList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    const path = this.props.match.path;
    const deviceId = safeDeepGet(this.props.match.params, 'id');
    this.state = {
      modalDetail: false,
      deviceId: deviceId,
      bpfilterlist: [],
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      file: '',
      array: [],

      headerTable: '',
      valuesArray: [],
      formValues: [],
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  handleImportClick = row => {
    this.toggleDetail();
  };
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  componentDidMount() {
    const { deviceId } = this.state;
    if (deviceId == undefined) {
      this.props.ListAll();
    } else {
      this.props.List(deviceId);
    }
  }
  showToastFailed() {
    const { isEdit } = this.state;
    var toastType = 'error';
    var message = 'Gagal Tambah CSV RFID';
    if (isEdit) {
      toastType = 'error';
      message = 'Gagal Update CSV RFID';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = 'Berhasil Tambah CSV RFID';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update CSV RFID';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          this.showToast();
          this.handleImportClick();
          this.componentDidMount();
          // this.props.history.replace('/ingredient/list');
          // window.location.reload();
        } else {
          this.showToastFailed();
        }
      }
    }
    if (this.props.readRFID !== prevProps.readRFID) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['readRFID', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code == Result.HTTP.OK) {
        const body = safeDeepGet(this.props, ['readRFID', 'body', 'user'], {});
        this.setState({ formValues: body });
      }
    }
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleOnChange = e => {
    const file = e.target.files[0];
    this.setState({ file });
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        const valuesArray = results['data'];
        this.setState({ valuesArray });
        const rowsArray = [];
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
        });

        this.setState({ rowsArray });
      }.bind(this),
    });
  };

  handleSubmit() {
    const payload = {
      rfid: this.state.valuesArray,
    };
    this.props.SetCsv(payload);
    this.setState({ valuesArray: [] });
  }

  render() {
    const { deviceId, valuesArray, formValues } = this.state;

    const defaultSorted = [
      {
        dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];
   
    const arr_data = formValues;
    const headerKeys = Object.keys(Object.assign({}, ...arr_data));
    const dataheader = {};
    const header = [];
    for (const id in headerKeys) {
      const data = headerKeys[id];
      safeDeepSet(dataheader, [id, 'text'], data);
      safeDeepSet(dataheader, [id, 'dataField'], data);

      // set formatter text in table
      if (data == 'imgUrl') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.imgUrl}</p>
          </>
        ));
      }
      if (data == 'description') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.description}</p>
          </>
        ));
      }
      if (data != 'quota') {
        header.push(dataheader[id]);
      }
    }
    const nameBP = safeDeepGet(
      this.props,
      ['readRfidResult', 'body', 'name'],
      ''
    );
    const headers = [
      {
        label: 'id',
        key: 'id',
      },
      {
        label: 'rule',
        key: 'rule',
      },
      {
        label: 'name',
        key: 'name',
      },
    ];
    const separator = ','; //if want csv File using commma change with (',') or something else
    const csvLink = {
      headers: headers,
      data: formValues,
      separator: separator,
      filename: 'RFID ' + nameBP + '.csv',
    };

    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25, //Change if to much
      totalSize: arr_data.length, // replace later with size,
      custom: true,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Import & Export RFID')}</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="10">
                        <div className="text-sm-end">
                          <CSVLink
                            {...csvLink}
                            className="btn btn-franke white mb-3"
                          >
                            Export to CSV
                          </CSVLink>
                        </div>
                      </Col>
                      <Col xs="2">
                        <div className="text-sm-start">
                          <Button
                            className="btn btn-franke white "
                            onClick={this.handleImportClick}
                          >
                            Import CSV
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <br />

                      {formValues.length == 0 ? null : (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="rfid"
                          columns={header}
                          data={formValues}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="rfid"
                              columns={header}
                              data={formValues}
                              search
                            >
                              {toolkitprops => (
                                <React.Fragment>
                                  <Row className="mb-2"></Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          {...toolkitprops.baseProps}
                                          {...paginationTableProps}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            'table align-middle table-nowrap table-hover'
                                          }
                                          bordered={true}
                                          striped={true}
                                          responsive
                                          ref={this.node}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )}
                      <Modal
                        size="lg"
                        isOpen={this.state.modalDetail}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={this.toggleDetail} tag="h4">
                          {'Import RFID'}
                        </ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col xs="7">
                              <div className="text-sm-center">
                                <input
                                  className="form-control"
                                  type="file"
                                  accept=".csv"
                                  id="csvFile"
                                  onChange={this.handleOnChange}
                                ></input>
                              </div>
                            </Col>
                            <Col xs="5">
                              <div className="text-sm-center">
                                <Button
                                  className="btn btn-franke white "
                                  onClick={this.handleSubmit}
                                >
                                  Import CSV
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {valuesArray.length == 0 ? null : (
                              <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField="rfid"
                                columns={header}
                                data={valuesArray}
                              >
                                {({
                                  paginationProps,
                                  paginationTableProps,
                                }) => (
                                  <ToolkitProvider
                                    keyField="rfid"
                                    columns={header}
                                    data={valuesArray}
                                    search
                                  >
                                    {toolkitprops => (
                                      <React.Fragment>
                                        <Row className="mb-2"></Row>
                                        <Row>
                                          <Col xl="12">
                                            <div className="table-responsive">
                                              <BootstrapTable
                                                {...toolkitprops.baseProps}
                                                {...paginationTableProps}
                                                defaultSorted={defaultSorted}
                                                classes={
                                                  'table align-middle table-nowrap table-hover'
                                                }
                                                bordered={true}
                                                striped={true}
                                                responsive
                                                ref={this.node}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-30">
                                          <Col className="pagination pagination-rounded justify-content-end mb-2">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    )}
                                  </ToolkitProvider>
                                )}
                              </PaginationProvider>
                            )}
                          </Row>
                        </ModalBody>
                      </Modal>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: safeDeepGet(state, ['rfid', 'loading'], true),
  createResult: safeDeepGet(state, ['rfid', 'create'], {}),
  readRfidResult: safeDeepGet(state, ['rfid', 'list'], {}),
  readRFID: safeDeepGet(state, ['rfid', 'listAll'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  List: id => dispatch(list(id)),
  ListAll: () => dispatch(listAll()),
  InputCsv: payload => dispatch(create(payload)),
  SetCsv: payload => dispatch(setAll(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RFIDList);
